import {FC, useMemo, useState} from "react";
import {usePlanner} from "./PlannerContext";
import moment, {Moment} from "moment";
import {useApi} from "../api/APIContext";
import {Project, Task, Team, Workorder} from "../api/dto";
import {Button} from "../components/form/Button";
import {
  faArchive,
  faBoxArchive, faCheckCircle,
  faCheckSquare, faCircleNotch, faComment,
  faLocationPin, faPaperPlane, faPencil,
  faPeopleGroup,
  faPerson,
  faPhone, faPhotoFilm, faTrash,
  faUndo
} from "@fortawesome/free-solid-svg-icons";
import {AddOrderModal} from "../modals/AddOrderModal";
import {useModal} from "../components/layout/ModalProvider";
import {DeleteOrderModal} from "../modals/DeleteOrderModal";
import React from "react";
import {OrderAutocomplete} from "../components/form/Autocomplete";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {WorkorderDetailsModal} from "../modals/WorkorderDetailsModal";
import {ArchiveModal} from "../modals/ArchiveModal";
import {UnarchiveModal} from "../modals/UnarchiveModal";
import {DeleteWorkordersModal} from "../modals/DeleteWorkordersModal";
import {ProjectDetailsModal} from "../modals/ProjectDetailsModal";
import {HoverHint} from "../components/content/HoverHint";
import {usePersistentState} from "../util/usePersistentState";
import {EnrichedTeamDayAvailability, InteractiveEmployeeAvatar} from "./components/WeekdayPlanner";
import {useTeamAvailability} from "./hooks/useTeamAvailability";
import {SendWorkordersModal} from "../modals/SendWorkordersModal";

export const WorkorderOverview: FC<{compact: boolean}> = (props) => {
  const {day} = usePlanner()
  const {workorders, projects, teams, tasks} = useApi()
  const teamsMap = useMemo(() => {
    return teams.reduce((acc, t) => {
      acc[t.id] = t
      return acc
    }, {} as {[key: string]: Team})
  }, [teams]);
  return <div className={"max-w-screen-2xl mx-auto"}>
    <WorkorderManager workorders={workorders} tasks={tasks} projects={projects} teams={teams} teamsMap={teamsMap} day={moment(day)} />
  </div>
}


interface ManagedWorkorder {
  workorder: Workorder|null,
  task: Task,
  team: Team|null
  project: Project|null
  state: 'concept'|'sent'|'finished'|'archived'
}
const WorkorderManager: FC<{workorders: Workorder[], tasks: Task[], teams: Team[], projects: Project[], teamsMap: {[id: string]: Team}, day: Moment}> = props => {
  const tasksToday = useMemo(() => props.tasks.filter(t => moment(t.startAt).isSame(props.day, 'day')), [props.tasks, props.day])
  const teamsToday = useMemo(() => props.teams.sort((a,b) => a.order - b.order).filter(t => tasksToday.some(o => o.teamId === t.id)), [props.teams, tasksToday])
  const managedWorkorders = useMemo(() => {
    return tasksToday.map(task => {
      const workorder = props.workorders.find(o => o.id.toString() === task.id.toString()) ?? null
      return {
        task: task,
        workorder: workorder,
        team: task.teamId ? props.teamsMap[task.teamId] ?? null : null,
        project: props.projects.find(p => p.id === task?.projectId) ?? null,
        state: workorder ? (workorder.archivedAt ? 'archived' : (workorder.isFinished ? 'finished' : 'sent')) : 'concept'
      } as ManagedWorkorder
    })
  }, [tasksToday, props.teamsMap, props.workorders, props.projects])
  const [showConcept, setShowConcept] = usePersistentState<boolean>('workorderFilter_showConcept', true)
  const [showSent, setShowSent] = usePersistentState<boolean>('workorderFilter_showSent', true)
  const [showFinished, setShowFinished] = usePersistentState<boolean>('workorderFilter_showFinished', true)
  const [showArchived, setShowArchived] = usePersistentState<boolean>('workorderFilter_showArchived', false)
  const filteredManagedWorkorders = useMemo(() => {
    return managedWorkorders
      .filter(mw => mw.state === 'concept' ? showConcept : true)
      .filter(mw => mw.state === 'sent' ? showSent : true)
      .filter(mw => mw.state === 'finished' ? showFinished : true)
      .filter(mw => mw.state === 'archived' ? showArchived : true)
  }, [showConcept, showSent, showFinished, showArchived, managedWorkorders])
  const managedWorkordersWithoutTeam = filteredManagedWorkorders.filter(t => t.team === null)
  return <div>
    <h1 className={"font-bold text-2xl"}>{props.day.toDate().toLocaleDateString('nl', {
      weekday: 'long',
      day: 'numeric',
      month: 'long'
    })}</h1>
    <div className={'grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 mt-3 mb-8 max-w-2xl gap-3'}>
      <label
        className={`flex items-center ${showConcept ? 'text-blue-950 bg-blue-50' : 'text-slate-600'} space-x-2 rounded border border-slate-300 px-2 h-8 hover:cursor-pointer hover:bg-slate-100`}>
        <input type={'checkbox'} checked={showConcept} onChange={() => setShowConcept(!showConcept)}/>
        <span className={"text-sm font-medium"}>Concept ({managedWorkorders.filter(w => w.state === 'concept').length})</span>
      </label>
      <label
        className={`flex items-center ${showSent ? 'text-blue-950 bg-blue-50' : 'text-slate-600'} space-x-2 rounded border border-slate-300 px-2 h-8 hover:cursor-pointer hover:bg-slate-100`}>
        <input type={'checkbox'} checked={showSent} onChange={() => setShowSent(!showSent)}/>
        <span className={"text-sm font-medium"}>Verstuurd ({managedWorkorders.filter(w => w.state === 'sent').length})</span>
      </label>
      <label
        className={`flex items-center ${showFinished ? 'text-blue-950 bg-blue-50' : 'text-slate-600'} space-x-2 rounded border border-slate-300 px-2 h-8 hover:cursor-pointer hover:bg-slate-100`}>
        <input type={'checkbox'} checked={showFinished} onChange={() => setShowFinished(!showFinished)}/>
        <span className={"text-sm font-medium"}>Afgerond ({managedWorkorders.filter(w => w.state === 'finished').length})</span>
      </label>
      <label
        className={`flex items-center ${showArchived ? 'text-blue-950 bg-blue-50' : 'text-slate-600'} space-x-2 rounded border border-slate-300 px-2 h-8 hover:cursor-pointer hover:bg-slate-100`}>
        <input type={'checkbox'} checked={showArchived} onChange={() => setShowArchived(!showArchived)}/>
        <span className={"text-sm font-medium"}>Gearchiveerd ({managedWorkorders.filter(w => w.state === 'archived').length})</span>
      </label>
    </div>

    {teamsToday.map((team, i) => {
      const teamManagedWorkorders = filteredManagedWorkorders.filter(t => t.team?.id === team.id)
      return <WorkorderManagerTeamOverview key={i} managedWorkorders={teamManagedWorkorders} team={team} day={props.day} />
    })}
    {managedWorkordersWithoutTeam.length > 0 && <div>
      <h2 className={'font-medium text-xl'}>Zonder team</h2>
      {managedWorkordersWithoutTeam.map((managedWorkorder, i) => {
        return <WorkorderManagerCard key={i} managedWorkorder={managedWorkorder} day={props.day}/>
      })}
    </div>}
  </div>
}
const WorkorderManagerTeamOverview: FC<{managedWorkorders: ManagedWorkorder[], day: Moment, team: Team}> = props => {
  const tasks = props.managedWorkorders.map(mw => mw.task)
  const sendModal = useModal({title: "Concepten versturen", size: 'xl', body: <SendWorkordersModal tasks={tasks} />})
  return <div className={'mt-8'}>
    <div className={'flex items-center'}>
      <div className={'flex-1'}>
        <h2 className={'font-medium text-xl'}>{props.team.name}</h2>
        <TeamCast team={props.team} day={props.day}/>
      </div>
      <Button type={'primary'} size={'md'} icon={faComment} text={'Concepten versturen'} onClick={() => sendModal.open() }/>
    </div>
    {props.managedWorkorders.sort(sortManagedWorkorderByStartAt).map((managedWorkorder, j) => {
      return <WorkorderManagerCard key={j} managedWorkorder={managedWorkorder} day={props.day}/>
    })}
  </div>
}
const WorkorderManagerCard: FC<{ day: Moment, managedWorkorder: ManagedWorkorder }> = props => {
  const cardStyle = {
    'concept': {
      avatarClasses: 'bg-slate-400',
      avatarIcon: faPencil,
      stateName: 'Concept',
      accentTextColor: 'text-slate-400',
    },
    'sent': {
      avatarClasses: 'bg-blue-800',
      avatarIcon: faPaperPlane,
      stateName: 'Verstuurd',
      accentTextColor: 'text-blue-800',
    },
    'finished': {
      avatarClasses: 'bg-green-600',
      avatarIcon: faCheckCircle,
      stateName: 'Afgerond',
      accentTextColor: 'text-green-600',
    },
    'archived' : {
      avatarClasses: 'bg-slate-800',
      avatarIcon: faArchive,
      stateName: 'Gearchiveerd',
      accentTextColor: 'text-zinc-800',
    },
  }[props.managedWorkorder.state]
  const {focusTask, setDay} = usePlanner()
  const {quantityTypes, sendWorkorders} = useApi()
  const projectDetailsModal = useModal({title: `Project #${props.managedWorkorder.project?.projectNumber}`, size: '2xl', body: <ProjectDetailsModal focusTask={(task) => focusTask(task)} goToDay={(day) => setDay(day)}  project={props.managedWorkorder.project!} />})

  const quantityType = quantityTypes.find(q => q.id === props.managedWorkorder.task.quantityType)

  const sendWorkorder = async () => {
    await sendWorkorders([props.managedWorkorder.task])
  }
  const detailsModal = useModal({title: "Werkbon", size: 'xl', body: <WorkorderDetailsModal order={props.managedWorkorder.workorder!} />})
  const archiveModal = useModal({title: "Archiveren", size: 'xl', body: <ArchiveModal order={props.managedWorkorder.workorder!} />})
  const unarchiveModal = useModal({title: "Dearchiveren", size: 'xl', body: <UnarchiveModal order={props.managedWorkorder.workorder!} />})
  const deleteModal = useModal({title: "Werkbonnen intrekken", size: 'xl', body: <DeleteWorkordersModal orders={[props.managedWorkorder.workorder!]} />})

  // const noteModal = useModal({title: "Notitie toevoegen", size: 'xl', body: <Note task={props.managedWorkorder.task} />})

  return <div className={'flex items-stretch border border-slate-200 bg-white rounded px-2 py-2 mt-2'}>
    <HoverHint hint={cardStyle.stateName}>
      <div className={`h-12 w-12 rounded ${cardStyle.avatarClasses} flex items-center justify-center mr-3`}>
        <FontAwesomeIcon icon={cardStyle.avatarIcon} className={'text-white text-xl'}/>
      </div>
    </HoverHint>
    <div className={'flex-1 flex flex-col justify-center'}>
      <div className={`${cardStyle.accentTextColor} text-xs uppercase font-bold`}>{cardStyle.stateName}</div>
      <div>
        {props.managedWorkorder.project ? <>
          <button className={`font-medium text-sm text-blue-950 underline`} onClick={() => projectDetailsModal.open()}>
            Project #{props.managedWorkorder.project.projectNumber} - {props.managedWorkorder.project.description}
          </button>
        </> : <span className={'font-medium text-sm'}>Overig project</span>}
      </div>
    </div>
    <div className={'flex-1 flex flex-col justify-center text-sm border-l border-slate-200 pl-3 ml-3 -my-2 py-2'}>
      {(props.managedWorkorder.task.quantityAmount !== null && quantityType) ? <div className={'font-medium'}>
        {props.managedWorkorder.task.quantityAmount} m² {quantityType.name}
      </div> : <div>-</div>}
      <div>
        {props.managedWorkorder.task.description}
      </div>
    </div>
    <div className={'flex items-center space-x-3 text-sm border-l border-slate-200 pl-3 ml-3 -my-2 py-2'}>
      {props.managedWorkorder.state === 'concept' ? <>
        <Button type={'secondary'} size={'sm'} icon={faPaperPlane} text={'Versturen'} onClick={() => sendWorkorder()} />
        {/*<Button type={'secondary'} size={'sm'} icon={faComment} text={'Notitie'} onClick={() => addNote()} />*/}
      </> :<></>}

      {props.managedWorkorder.state === 'sent' ? <>
        <Button type={'secondary'} size={'sm'} icon={faUndo} text={'Intrekken'} onClick={() => deleteModal.open()} />
        <Button type={'danger'} size={'sm'} icon={faArchive} text={'Archiveren'} onClick={() => archiveModal.open()} />
        {/*<Button type={'secondary'} size={'sm'} icon={faComment} text={'Notitie'} onClick={() => addNote()} />*/}
      </> :<></>}

      {props.managedWorkorder.state === 'finished' ? <>
        <Button type={'secondary'} size={'sm'} icon={faPhotoFilm} text={`Notities (${props.managedWorkorder.workorder?.feedback?.filter(f => f.answerType === 'questions').length}) - Fotos (${props.managedWorkorder.workorder?.feedback?.filter(f => f.answerType === 'image').length})`} onClick={() => detailsModal.open()} />
        <Button type={'danger'} size={'sm'} icon={faArchive} text={'Archiveren'} onClick={() => archiveModal.open()} />
        {/*<Button type={'secondary'} size={'sm'} icon={faComment} text={'Notitie'} onClick={() => addNote()} />*/}
      </> :<></>}

      {props.managedWorkorder.state === 'archived' ? <>
        <Button type={'secondary'} size={'sm'} icon={faUndo} text={'Terugzetten'} onClick={() => unarchiveModal.open()} />
        {/*<Button type={'secondary'} size={'sm'} icon={faComment} text={'Notitie'} onClick={() => addNote()} />*/}
      </> :<></>}
    </div>
  </div>
}

const sortManagedWorkorderByStartAt = (a: ManagedWorkorder, b: ManagedWorkorder) => {
  const aStartAt = a.task.startAt ? moment(a.task.startAt) : null
  const bStartAt = b.task.startAt ? moment(b.task.startAt) : null
  if (!aStartAt && !bStartAt) return 0
  if (!aStartAt) return -1
  if (!bStartAt) return 1
  return aStartAt.isBefore(bStartAt) ? -1 : 1
}

const TeamCast: FC<{team: Team, day: Moment}> = props => {
  const {teams, employees, holidays} = useApi()
  const ta = useTeamAvailability()
  const tdas = useMemo(() => {
    if (props.day.isoWeekday() === 0) return ta?.sunday ?? []
    if (props.day.isoWeekday() === 1) return ta?.monday ?? []
    if (props.day.isoWeekday() === 2) return ta?.tuesday ?? []
    if (props.day.isoWeekday() === 3) return ta?.wednesday ?? []
    if (props.day.isoWeekday() === 4) return ta?.thursday ?? []
    if (props.day.isoWeekday() === 5) return ta?.friday ?? []
    if (props.day.isoWeekday() === 6) return ta?.saturday ?? []
    return []
  }, [ta, props.day])
  const enrichedTeamAvailabilities = useMemo(() => {
    const t = tdas.map(tda => {
      return {
        ...tda,
        team: teams.find(t => t.id === tda.teamId),
        employees: employees.filter(e => tda.employeeIds.includes(e.id))
      }
    }).filter(x => x.team !== undefined && x.teamId !== 'absence') as EnrichedTeamDayAvailability[] | undefined
    return t?.sort((a, b) => a.team.order - b.team.order) ?? []
  }, [tdas, teams, employees]);

  const tda = enrichedTeamAvailabilities.find(tda => tda.teamId === props.team.id)

  return <div className={"flex items-center h-6 mt-1 mb-2"}>
    {!tda && <FontAwesomeIcon icon={faCircleNotch} className={'text-slate-400'} spin={true} />}
    {tda?.employees.map((e, i, s) => <InteractiveEmployeeAvatar key={i} date={props.day.toDate()} tda={tda} teams={enrichedTeamAvailabilities} e={e} s={s}/>)}
  </div>
}