import React, {FC} from "react";
import * as fa from "@fortawesome/free-solid-svg-icons"
import {useApi} from "../api/APIContext";
import {ModalFooter} from "../components/layout/ModalProvider";
import { Input } from "../components/form/Input";
import {ValidationError} from "../api/api";
import {Color, Employee} from "../api/dto";


export const EditEmployeeBSNModal: FC<{employee: Employee}> = ({employee}) => {
  const {updateEmployeeMetadata} = useApi()
  const [bsn, setBsn] = React.useState('')
  const [errors, setErrors] = React.useState<string[]>([])
  const confirm = async () => {
    await updateEmployeeMetadata(employee.id, undefined, bsn)
      .catch(e => {
        if (e instanceof ValidationError) {
          setErrors(Object.values(e.errors).flat())
        }
        throw e
      })
  }

  return <div className={"space-y-2"}>
    <Input label={'Nieuwe BSN'} value={bsn} focus={true} onChange={setBsn} type={'text'} />
    {errors.length > 0 && <div className={"text-red-600 text-sm"}>
      {errors.map((e, i) => <p key={i}>{e}</p>)}
    </div>}
    <ModalFooter icon={fa.faSave} text={"Opslaan"} onSubmit={confirm} />
  </div>
}
