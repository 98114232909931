import React, {FC, useState} from "react";
import {Employee} from "../../api/dto";
import {getAvailableColors} from "./EmployeeAvatar";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import {Button} from "../../components/form/Button";
import {useApi} from "../../api/APIContext";

export const ColorEditor: FC<{employee: Employee}> = ({employee}) => {
  const {updateEmployeeMetadata} = useApi()
  const colors = getAvailableColors()
  const [initialValue, setInitialValue] = useState<string|null>(employee.metadata.color ?? null)
  const [selected, setSelected] = React.useState<string | null>(initialValue)
  const save = async () => {
    if (selected === null) {
      return
    }
    await updateEmployeeMetadata(employee.id, selected)
    setInitialValue(selected)
  }
  return <div className={'flex flex-col items-stretch space-y-1'}>
    <div className={"flex flex-wrap"}>
      {Object.entries(colors).map(([color, dot], i) => {
        return <button key={i} onClick={() => setSelected(color)} className={`h-8 w-8 flex items-center rounded-lg justify-center mb-2 mr-2 border-2 ${selected === color ? 'border-blue-900' : 'border-slate-200'}`}>
          <div className={`w-5 h-5 rounded-full full ${dot}`}></div>
        </button>
      })}
    </div>
    <div>
      {initialValue !== selected && <>
        <Button type={'primary'} size={'sm'} icon={faSave} text={'Wijzigingen opslaan'} onClick={save} />
      </>}
    </div>
  </div>
}